import React, { lazy } from "react";
import { Routes, Route, Outlet, Link } from "react-router-dom";
const Homepage = lazy(() => import("./pages/Homepage/index.js"));
const Topbar = lazy(() => import("./components/Topbar/index.js"));
const SomeOtherPage = lazy(() => import("./pages/SomeOtherPage/index.js"));

const App = () => {
  let subdomain;
  try {
    subdomain = window.location.hostname.split(".")[0];
  } catch (e) {
    subdomain = "";
  }
  if (process.env.NODE_ENV === "production" && subdomain !== "prod") {
    window.location.href = "https://maps.app.goo.gl/VYNb3WEogMca22bu8";
    return <>Redirecting You To Google Maps...</>;
  } else {
    return (
      <>
        <Topbar />
        <Routes>
          <Route path="/" element={<Homepage />} />
          <Route path="/other" element={<SomeOtherPage />} />
        </Routes>
      </>
    );
  }
};

export default App;
